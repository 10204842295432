import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'gatsby';
import Seo from 'components/Seo/Seo';
import style from 'components/Section/Section.module.scss';

const translations = defineMessages({
	metaTitle: {
		id: 'metaTitle.404',
		defaultMessage: 'Page not found'
	}
});

const NotFoundPage = () => {
	const { formatMessage } = useIntl();
	return (
		<>
			<Seo
				title={formatMessage(translations.metaTitle)}
			/>
			<Helmet>
				<meta name="robots" content="noindex,follow" />
			</Helmet>
			<div className={style.notFoundimageWrapper}>
				<div className={style.container}>
					<h1 className={style.heading}>
						<FormattedMessage id="404.title" defaultMessage="Sorry, the page you are looking for was not found"/>
					</h1>

					<Link to="/">
						<button className={style.button}>
							<FormattedMessage id="button.back" defaultMessage="Back to main page"/>
						</button>
					</Link>
				</div>
			</div>
		</>
	);
};

export default NotFoundPage;
