import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import ogTW from 'assets/images/OG_TW.png';
import ogFB from 'assets/images/OG_FB.png';

const config = require(`../../../config.${process.env.NODE_ENV || process.env.GATSBY_APP}.js`);

interface Props {
	metaTitle: string;
	metaDescription: string;
}

const Seo = ({metaTitle, metaDescription}: Props) => {
	const { locale } = useIntl();

	const description = [
		{ name: 'description', content: metaDescription }
	];

	const facebook = [
		{ property: 'og:url', content: config.BASE_URL },
		{ property: 'og:site_name', content: 'Spectro Finance, UAB' },
		{ property: 'og:type', content: 'website' },
		{ property: 'og:title', content: metaTitle },
		{ property: 'og:description', content: metaDescription },
		{ property: 'og:image', content: `${config.BASE_URL + ogFB}` },
		{ property: 'og:image:alt', content: 'Spectro Finance logo in dark blue background' },
		{ property: 'og:image:secure_url', content: `${config.BASE_URL + ogFB}` },
		{ property: 'og:image:width', content: '1200' },
		{ property: 'og:image:height', content: '630' },
		{ property: 'og:locale', content: 'lt' }
	];

	const twitter = [
		{ property: 'twitter:card', content: 'summary_large_image' },
		{ property: 'twitter:url', content: config.BASE_URL },
		{ property: 'twitter:secure_url', content: config.BASE_URL },
		{ property: 'twitter:title', content: metaTitle },
		{ property: 'twitter:description', content: metaDescription },
		{ property: 'twitter:image', content: `${config.BASE_URL + ogTW}` },
		{ property: 'twitter:image:alt', content: 'Spectro Finance logo in dark blue background' },
		{ property: 'twitter:image:width', content: '626' },
		{ property: 'twitter:image:height', content: '335' }
	];

	return (
		<Helmet
			title={metaTitle}
			meta={[...description, ...facebook, ...twitter]}
			link={[
				{ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' }
			]}
		>
			<html lang={locale} />
		</Helmet>
	);
};

export default Seo;
